<template>
      <div>
        <el-card class="box-card">
            <div slot="header"  class="block">
            <el-radio-group class="radioBox" v-model="radio1">
                <el-radio :label="1">日</el-radio>
                <el-radio :label="2">月</el-radio>
                <el-radio :label="3">年</el-radio>
            </el-radio-group>
            <br/>
            <el-date-picker v-show="(radio1===1)"
                v-model="dayVal"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <el-date-picker v-show="(radio1===2)"
                v-model="monthVal"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
           </el-date-picker>
           <div v-show="(radio1===3)">
            <el-date-picker 
                v-model="yearValS"
                type="year"
                placeholder="开始年份">
            </el-date-picker>
            <el-date-picker 
                v-model="yearValE"
                type="year"
                placeholder="结束年份">
            </el-date-picker>
           </div>
            
            <el-button  type="primary" style="margin-left:10px" @click="selData"  icon="el-icon-search">查找</el-button>
        </div>
        <el-tag>总治疗数：{{ allTotal }}</el-tag>
        <el-tag style="margin-left:15px">总治疗人数：{{ peopelNum }}</el-tag>
        <el-tag style="margin-left:15px" type="warning">选择时间总治疗数:{{ selTotal }}</el-tag>
        <el-table :data="totalTable">
            <el-table-column  prop="id"  label="序号" >
                <template  slot-scope="scope">
                      {{ scope.$index +1}} 
              </template>
             </el-table-column>
             <el-table-column prop="time" label="日期">
              <template  slot-scope="scope">
                      {{ scope.row.time}}
                   
              </template>
             </el-table-column>
             <el-table-column prop="number" label="治疗总次数">
              <template  slot-scope="scope">
                      {{ scope.row.number}}
              </template>
             </el-table-column>
             <el-table-column prop="number" label="总治疗人数">
              <template  slot-scope="scope">
                      {{ scope.row.peopleNumber}}
              </template>
             </el-table-column>
           </el-table>
           <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,30,50]"
          :page-size="30"
          layout="total, sizes, prev, pager, next, jumper"
          style="float:right"
          :total="total">
       </el-pagination> -->
        </el-card>
        

      </div>
</template>
<script>
import { selStatistscsLs } from '@/api/cureList'
    export default {
    name:'Statistics',
    data(){
        return{
             totalTable:[],
             radio1:1,
             yearValS:'',
             yearValE:'',
             monthVal:'',
             dayVal:'',
             pageNo:1,
             pageSize:10,
             total:0,
             option:{},
             allTotal:0,
             selTotal:0,
             peopelNum:0
        }
    },
    methods:{
      async  selData(){
        const opt = this.option;
        opt.type = this.radio1
        switch (this.radio1) {
            case 1:
                opt.startTime = this.$moment(this.dayVal[0]).format("YYYY-MM-DD");
                opt.endTime = this.$moment(this.dayVal[1]).format("YYYY-MM-DD");
            break;
            case 2:
                opt.startTime = this.$moment(this.monthVal[0]).format("YYYY-MM");;
                opt.endTime = this.$moment(this.monthVal[1]).format("YYYY-MM");;
                break;
            case 3:
               opt.startTime = this.$moment(this.yearValS).format("YYYY");
               opt.endTime = this.$moment(this.yearValE).format("YYYY");
                break;
            default:
                break;
        }
        const res =  await selStatistscsLs(opt);   
        if(res.code === 200){
            const list = res.data.peopleNumbers;
           this.totalTable = list;
           this.allTotal = res.data.total;
           this.selTotal = res.data.count;
           this.peopelNum = res.data.peopleNumber;
         }
        },
      handleSizeChange(val){   
        this.pageSize = val; 
        this.getSelOption();
      },
      handleCurrentChange(val){
        this.pageNo = val;
        this.getSelOption()
      },
      getSelOption(){
        const opt = {
           pageSize:this.pageSize,
           pageNo:this.pageNo,
        }
        this.selData(opt);
      },

    },
    created(){
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.dayVal = [firstDay,lastDay];
        this.selData();
     
    }
    }
</script>
<style scoped lang="scss">
.block{ 
    display: flex;
    justify-content: flex-start;
    .radioBox{
      width: 200px;
      padding-top: 10px;
    }
}
</style>