import request from '../utils/request'
// 用户住院信息
export function getCureList(params) {
    return request({
      url: '/index/list',
      method: 'get',
      params,
    });
  }
// 删除住院信息
  export function delUserInfo(id) {
    return request({
      url: `/index/${id}`,
      method: 'delete',
      data:id
    });
  }

// 更新住院信息
export function saveUserInfo(data) {
  return request({
    url: '/index/hospital',
    method: 'put',
    data
  });
}
// 更新住院检查信息
export function saveCureInfo(data){
  return request({
    url: '/index/cure/item',
    method: 'put',
    data
  });
}

// 查询治疗人数统计
export function selStatistscsLs(params){
  return request({
    url: '/index/count/people/number',
    method: 'get',
    params
  });
}

///api/index/cure/item

